<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="save()"
        >
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-text-field
                v-model.trim="rgNombre"
                type="text"
                label="Nombre"
                dense
                outlined
                :rules="
                  rules.required.concat([
                    rules.maxLength(rgNombre, 50),
                    rules.requiredTrim(rgNombre)
                  ])
                "
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="pb-0">
              <v-text-field
                v-model.trim="rgDescrip"
                label="Descripción de regla comercial"
                dense
                outlined
                :rules="
                  rules.required.concat([
                    rules.maxLength(rgDescrip, 50),
                    rules.requiredTrim(rgDescrip)
                  ])
                "
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="pb-0">
              <v-select
                v-model="rgProcSelect"
                :items="rgProcCombo"
                item-text="rgProcDescripGral"
                item-value="id"
                label="Procedimiento"
                outlined
                clearable
                dense
                return-object
                @input="permitirAcumular(rgProcSelect)"
                @change="
                  rgProcSelect
                    ? ((descProcNombre = rgProcSelect.rgProcDescripGral),
                      resetParametro())
                    : (descProcNombre = null)
                "
                :rules="rules.required"
                @blur="obtenerVariable()"
              >
              </v-select>
            </v-col>
          </v-row>
          <p v-if="rgProcSelect">
            <i>
              <span style="font-size: 12px;">
                Descripción general del procedimiento disponible seleccionado:
                {{ descProcNombre }}</span
              ></i
            >
          </p>
          <v-row>
            <v-col cols="12" class="pb-0">
              <p v-if="rgProcSelect">
                <v-row>
                  <v-col cols="5" sm="5" md="5" class="py-0">
                    <v-text-field
                      outlined
                      type="text"
                      dense
                      ref="parametros"
                      label="Parámetros"
                      v-model="parametros"
                      :items="rgProcCombo"
                      item-text="value"
                      clearable
                      :rules="
                        rules.required.concat([
                          rules.maxLength(parametros, 150)
                        ])
                      "
                      :placeholder="
                        `Ej. ${
                          rgProcSelect != null
                            ? rgProcSelect.rgProcEjemploParametros
                            : ''
                        }`
                      "
                      :hint="
                        `Ej. ${
                          rgProcSelect != null
                            ? rgProcSelect.rgProcEjemploParametros
                            : ''
                        }`
                      "
                      persistent-hint
                    ></v-text-field>
                  </v-col>
                  <v-col cols="1" class="pa-0 mt-1">
                    <v-tooltip right max-width="40%">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on">
                          {{ infoIcon }}
                        </v-icon>
                      </template>
                      <span style="white-space: pre-line;">
                        Ingrese los parámetros correspondientes en el siguiente
                        orden:
                      </span>
                      <ol>
                        <ul>
                          <li v-if="rgProcSelect != null">
                            {{ rgProcSelect.rgProcDescripTotal }}
                          </li>
                        </ul>
                      </ol>
                    </v-tooltip>
                  </v-col>
                  <v-col cols="5" class="py-0">
                    <v-select
                      v-model="variableSelect"
                      :items="listVariables"
                      item-text="value"
                      item-value="id"
                      label="Variable"
                      outlined
                      clearable
                      dense
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="1" class="pa-0 mt-1">
                    <v-tooltip right max-width="40%">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on">
                          {{ infoIcon }}
                        </v-icon>
                      </template>
                      <span>
                        La variable prevalecerá sobre los parámetros para el
                        período indicado.
                      </span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </p>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="6" md="6" class="pb-0">
              <v-text-field
                v-model="periodoDesde"
                label="Período desde"
                dense
                outlined
                hint="Formato AAAAMM"
                onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                v-mask="'######'"
                :rules="
                  rules.required.concat(
                    [rules.validDateRange(periodoDesde, periodoHasta)],
                    rules.periodoYyyyMm
                  )
                "
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="pb-0">
              <v-text-field
                v-model="periodoHasta"
                label="Período hasta"
                dense
                hint="Formato AAAAMM"
                outlined
                onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                v-mask="'######'"
                :rules="
                  rules.required.concat(
                    [rules.validDateRange(periodoDesde, periodoHasta)],
                    rules.periodoYyyyMm
                  )
                "
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="6" class="py-0">
              <v-text-field
                v-model="orden"
                onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                label="Orden"
                dense
                outlined
                :rules="rules.positiveNumber"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-switch
                class="py-0"
                v-model="acumulable"
                label="Acumulable"
                dense
                outlined
                :disabled="disabledAcumulableSwitch"
              ></v-switch>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="set"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid || saving"
          form="form"
          color="primary"
          :loading="btnIsLoadingRegla"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mask } from "vue-the-mask";

export default {
  name: "EditReglasComerciales",
  props: {
    editRgItem: {
      type: Object
    }
  },
  directives: { mask },
  data: () => ({
    isFormValid: false,
    infoIcon: enums.icons.SNB_INFO,
    formEditTitle: enums.titles.EDITAR_RG_COMERCIAL,
    newTitle: enums.titles.NUEVA_RG_COMERCIAL,
    periodoDesde: null,
    periodoHasta: null,
    rules: rules,
    rgNombre: "",
    rgDescrip: "",
    rgProcSelect: null,
    btnIsLoadingRegla: false,
    variableSelect: null,
    disabledAcumulableSwitch: false,
    acumulable: false,
    rgProcCombo: [],
    parametros: "",
    descProcNombre: "",
    orden: null,
    acumula: false,
    rgVarId: null,
    listVariables: [],
    saving: false
  }),
  created() {
    this.procRgSelect();

    setTimeout(() => {
      if (this.editRgItem) {
        this.setRg();
      } else {
        this.newReglaComercial();
      }
    }, 200);
  },

  methods: {
    ...mapActions({
      saveReglaComercial: "configuracion/saveReglaComercial",
      getReglasComercialesProc: "configuracion/getReglasComercialesProc",
      getVariablesReglasComercialesTaxo:
        "configuracion/getVariablesReglasComercialesTaxo",
      setAlert: "user/setAlert"
    }),
    async procRgSelect() {
      const response = await this.getReglasComercialesProc();

      this.rgProcCombo = response;
      this.checkPermiteAcumular(this.rgProcCombo);
      if (this.editRgItem) {
        this.setRg();
      } else {
        this.newReglaComercial();
      }
    },
    resetParametro() {
      this.$refs.parametros?.reset();
    },
    checkPermiteAcumular(array) {
      const seleccionado = array.find(x => x.id == this.rgProcSelect);
      if (seleccionado) {
        this.permitirAcumular(seleccionado);
      }
    },
    permitirAcumular(item) {
      if (item.rgProcPermitirAcumular === true) {
        this.disabledAcumulableSwitch = false;
      } else {
        this.disabledAcumulableSwitch = true;
        this.acumulable = false;
      }
    },
    setRg() {
      this.rgId = this.editRgItem.rgId;
      this.rgNombre = this.editRgItem.rgNombre;
      this.rgDescrip = this.editRgItem.rgDescrip;
      this.rgProcSelect = this.rgProcCombo.find(
        x => x.id == this.editRgItem.rgProcId
      );

      this.descProcNombre = this.editRgItem.rgProcNombre;
      this.parametros = this.editRgItem.parametros;
      this.periodoDesde = this.editRgItem.periodoVigenciaDesde;
      this.periodoHasta = this.editRgItem.periodoVigenciaHasta;
      this.orden = this.editRgItem.orden;
      this.acumulable = this.editRgItem.acumulable;
      this.variableSelect = this.editRgItem.rgVariableId;
      this.obtenerVariable();
    },

    async newReglaComercial() {
      this.formEditTitle = this.newTitle;
    },
    async obtenerVariable() {
      //this.editRgItem.rgProcId
      //    ? this.editRgItem.rgProcId
      //    : (this.editRgItem.rgProcId = 0);
      const par = {
        procId: this.rgProcSelect.id,
        rgId: this.editRgItem ? this.editRgItem.rgId : 0
      };
      const variable = await this.getVariablesReglasComercialesTaxo(par);
      this.listVariables = variable;
    },
    async save() {
      if (this.saving) {
        return; // Evitar la ejecución duplicada si la función ya está en curso
      }
      this.saving = true;
      this.btnIsLoadingRegla = true;
      const data = {
        rgId: this.editRgItem?.rgId,
        rgNombre: this.rgNombre,
        rgVarId: this.variableSelect ? this.variableSelect : null,
        rgDescrip: this.rgDescrip,
        rgProcId: this.rgProcSelect.id,
        parametros: this.parametros,
        periodoVigenciaDesde: this.periodoDesde,
        periodoVigenciaHasta: this.periodoHasta,
        orden: Number(this.orden),
        acumulable: this.acumulable
      };
      const response = await this.saveReglaComercial(data);
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Guardado con éxito" });
        this.set();
      }
      this.saving = false;
      this.btnIsLoadingRegla = false;
    },

    set() {
      this.$emit("closeAndReload");
    }
  }
};
</script>

<style scoped>
.repeat input {
  border-color: red;
}
.repeat label {
  color: red;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;

  margin: 0;
}

::v-deep input[type="number"] {
  -moz-appearance: textfield;
}
</style>
