<template>
  <v-card>
    <v-container>
      <v-card-title class="pl-1 primary--text"
        >Cambios realizados por la regla</v-card-title
      >
      <v-data-table
        :headers="headersCambioRc"
        :items="cambioRcItems"
        class="elevation-1"
        :loading="isLoading"
      >
      </v-data-table>
    </v-container>

    <v-container>
      <v-card-title class="pl-1 primary--text"
        >Modificaciones de la regla</v-card-title
      >
      <v-data-table
        :headers="headersModifRc"
        :items="modificacionRcItems"
        class="elevation-1"
        item-key="rglcId"
        :loading="isLoading"
        show-expand
        :expanded.sync="expanded"
      >
        <template v-slot:[`item.acumulable`]="{ item }">
          <v-icon
            v-if="item.acumulable"
            small
            color="primary"
            class="d-flex justify-center"
          >
            {{ checkIcon }}
          </v-icon>
        </template>
        <template v-slot:[`item.data-table-expand`]="{ item, isExpanded }">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                @click="handleExpansion(item, isExpanded)"
                v-bind="attrs"
                size="20"
                v-on="on"
                >{{ isExpanded ? "mdi-account-check" : "mdi-account" }}</v-icon
              >
            </template>
            <span>Usuarios</span>
          </v-tooltip>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <strong>Fecha alta:</strong>
            {{ item.fecAlta }}
            <strong style="padding-left: 100px">Usuario alta:</strong>
            {{ item.usuAlta }}
            <strong style="padding-left: 100px">Fecha modificación:</strong>
            {{ item.fecModi }}
            <strong style="padding-left: 100px">Usuario modificación:</strong>
            {{ item.usuModi }}
          </td>
        </template>
      </v-data-table>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModalLog"> Cerrar </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";

export default {
  name: "LogReglasComerciales",
  props: ["reglaId"],
  data: () => ({
    checkIcon: enums.icons.CHECK_OUTLINE,
    isLoading: false,
    cambioRcItems: [],
    modificacionRcItems: [],
    headersCambioRc: [
      {
        text: "RgId",
        value: "rgId",
        sortable: false
      },
      {
        text: "RgIdAnterior",
        value: "rgIdAnterior",
        sortable: false
      },
      {
        text: "Fecha",
        value: "fecha",
        sortable: false
      },
      {
        text: "Grupo familiar",
        value: "benGrId",
        sortable: false
      },
      {
        text: "Agente de cuenta",
        value: "agectaId",
        sortable: false
      },
      {
        text: "Resultado",
        value: "resultado",
        sortable: false
      },
      {
        text: "Resultado anterior",
        value: "resultadoAnterior",
        sortable: false
      },
      {
        text: "Intereses a cobrar Id",
        value: "interesesAcobrarId",
        sortable: false
      },
      {
        text: "BoniRecaBenId",
        value: "boniRecaBenId",
        sortable: false
      },
      {
        text: "Observaciones",
        value: "observaciones",
        sortable: false
      },
      {
        text: "Operación",
        value: "operacion",
        sortable: false
      }
    ],
    headersModifRc: [
      {
        text: "Nombre",
        value: "rgNombre",
        sortable: false
      },
      {
        text: "Descripción",
        value: "rgDescrip",
        sortable: false
      },
      {
        text: "RgProcId",
        value: "rgProcId",
        sortable: false
      },
      {
        text: "Parámetros",
        value: "parametros",
        sortable: false
      },
      {
        text: "Orden",
        value: "orden",
        sortable: false
      },
      {
        text: "Período desde",
        value: "periodoVigenciaDesde",
        sortable: false
      },
      {
        text: "Período hasta",
        value: "periodoVigenciaHasta",
        sortable: false
      },
      {
        text: "Acumulable",
        value: "acumulable",
        sortable: false
      },
      { text: "", value: "data-table-expand", align: "end" }
    ],
    allowedActions: null,
    expanded: []
  }),
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.setCambiosRg();
    this.setModifRg();
  },
  methods: {
    ...mapActions({
      getLogsCambiosByRegla: "configuracion/getLogsCambiosByRegla",
      getLogsModificacionesByRegla: "configuracion/getLogsModificacionesByRegla"
    }),
    async setCambiosRg() {
      this.isLoading = true;
      const data = await this.getLogsCambiosByRegla(this.reglaId);
      this.cambioRcItems = data;
      this.isLoading = false;
    },
    async setModifRg() {
      this.isLoading = true;
      const response = await this.getLogsModificacionesByRegla(this.reglaId);
      this.modificacionRcItems = response;
      this.isLoading = false;
    },
    closeModalLog() {
      this.$emit("closeModalLog");
    },
    handleExpansion(item, state) {
      const itemIndex = this.expanded.indexOf(item);
      state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
    }
  }
};
</script>

<style lang="scss" scoped></style>
